import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { interval } from 'rxjs';

import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';

@Injectable()
export class UpdateService {
  constructor(swUpdate: SwUpdate, notificationsService: NotificationsService, translateService: TranslateService) {
    // let dialog;
    // interval(6000).subscribe(async () => {
    //   const test = await swUpdate.checkForUpdate();
    //   swUpdate.versionUpdates
    //     .pipe(
    //       filter((evt): evt is VersionReadyEvent => {
    //         return evt.type === 'VERSION_READY';
    //       })
    //     )
    //     .subscribe((evt) => {
    //       if (!dialog) {
    //         dialog = notificationsService
    //           .alert(translateService.instant('update.body'), translateService.instant('update.title'))
    //           .afterClosed()
    //           .pipe(take(1))
    //           .subscribe((evt) => {
    //             document.location.reload();
    //           });
    //       }
    //     });
    // });
  }
}
